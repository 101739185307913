<!--
 * @Descripttion: 显示样式：列表式
 * @Author: 彭博
 * @Date: 2020-07-27 10:28:15
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-03-04 11:40:05
-->
<template>
  <div class="detail-table" :class="{'is-ping':!isTiled,'is-list':isTiled}">
    <!-- :head-end-config="headAddConfig" 14-1 去掉末尾添加icon-->
    <en-table
      ref="table"
      :id="`table${column.field}`"
      :border="isTiled"
      :head-end-config="headAddConfig"
      auto-resize
      :data="column.subDetails"
      :loading="column.tableLoading"
      :show-header="isTiled"
      :span-method="arraySpanMethod"
      no-data-icon-size="small"
      max-height="400"
      :row-style="tableRowStyle"
      :header-cell-style="headCellStyle"
      :cell-class-name="cellClassFormat"
      :drag-config="{ dragRow: false }"
      :hover-row-config="lineEditConfig"
      :optimization="{scrollY: {gt: column.editable ? 20 : 100}}"
      @filter-sort-change="handleTableFilterChange"
    >
      <en-table-column width="50" type="index">
      </en-table-column>
      <!-- 列表式 -->
    <template v-if="isTiled">
      <template v-for="(row, lineIndex) in column.sub" >
        <en-table-column :width="columnStyleWidth(row)"
          v-if="columnDisplayable(row)"
          :filter-sort-config="filterConfig(row.fieldType)"
          :data="getData(row)"
          :min-width='columnStyleWidth(row)=="auto" ?220 : ""'  :key="row.name">
          <template slot="header">
            <div :class="row.field">
              <span >{{ row.name }}</span>
              <span class="head-requred" v-if="row.requrein === 0">*</span>
              <span v-if="row.suffix">{{ row.suffix === "1" ? "(元)" : row.suffix === "2" ? "(万元)" :`(${row.suffix})` }}</span>
            </div>
          </template>

          <template #edit='scope'>
            <div class="wrapper is-edit">
              <business-column-rendering
                :hide-label="true"
                :column="scope.row.fields[lineIndex]"
                :row-index="scope.rowIndex"
                :business-data="businessData"
                :check-prop="checkProp + '.subDetails.' + (scope.rowIndex) + '.fields.' + lineIndex"
              >
                <template>
                  <sub-set :column="scope.row.fields[lineIndex]" ref="subSet" :businessData="businessData" :row="scope.row" @handleShowExpand="handleShowExpand"></sub-set>
                </template>
              </business-column-rendering>
            </div>
          </template>
        </en-table-column>
      </template>
    </template>
      <!-- 平铺式 -->
      <en-table-column min-width="280" v-if="!isTiled" class='tiled-cloumn'>
        <template slot-scope="scope">
          <div class="wrapper-tile">
            <template v-for="(lineColumn, lineIndex) in scope.row.fields">
              <template  v-if="isShowField(lineColumn.field)">
                  <business-column-rendering
                    :key="lineColumn.uuid"
                    :column="lineColumn"
                    :business-data="businessData"
                    :check-prop="checkProp + '.subDetails.' + (scope.seq - 1) + '.fields.' + lineIndex"
                  >
                    <template>
                      <sub-set :column="lineColumn" :businessData="businessData" ref="subSet" :row="scope.row" @handleShowExpand="handleShowExpand"></sub-set>
                    </template>
                  </business-column-rendering>
              </template>
            </template>

          </div>
        </template>
      </en-table-column>
      <!-- 按钮组 子集展开数据集合 -->
      <en-table-column width="1" type="expand" v-if="hasSubSet">
        <template slot-scope="scope">
          <bm-subSet
            :expandStyle="expandStyle"
            v-if="subSetOptions(scope.row)"
            :key="subSetOptions(scope.row).field"
            class="bm-subSet-expand"
            :column="subSetOptions(scope.row)"
            :isTiled="isTiled"
            :business-data="businessData"
            :checkProp="checkProp + '.subDetails.' + (scope.seq - 1) + '.fields.' + subSetFindIndex(scope.row)">
          </bm-subSet>
        </template>
      </en-table-column>
    </en-table>
    <en-dialog
      :visible.sync="dialogVisible"
      width="35%"
      class='add-dialog'
      >
      <div slot='title'>
        批量添加
        <el-popover
            v-if="!!column.subDetails.length"
            placement="top-start"
            popper-class='info-popover'
            width="400"
            trigger="hover"
            >
            <ul class='info-list'>
              <li class='num1'>覆盖新增 <div class='content'>原有数据被删除，此次选择的数据将替代原有数据</div></li>
              <li class='num2'>依次新增 <div class='content'>此次选择的数据累加在原有数据后方</div></li>
            </ul>
            <span slot="reference"><en-icon  name='tishi' size='14' color='#fac135' class='pop-icon'></en-icon></span>
          </el-popover>
      </div>
      <div class='input-line-wrap'><span>行数</span> <en-input-number v-model.number="addLineNums" class='add-line-input' placeholder="请输入" size='small'></en-input-number></div>

      <span slot="footer" class="dialog-footer">
        <en-button type='green' @click="batchAddLine(2)" size="small">依次新增</en-button>
        <en-button type="primary" @click="batchAddLine(!!column.subDetails.length?1:2)" size="small">{{!!column.subDetails.length?'覆盖新增':'新增'}}</en-button>
      </span>
    </en-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { cloneDeep } from "lodash";
import { BusinessFieldType } from "../../../../data/BusinessCommonHeader";
import BMSubsetComponent from "../../subSet/BMSubsetComponent";
import subSet from "../../subSet";

export default {
  name: "detaiTable",
  componentName: "detaiTable",
  components: {
    "bm-subSet": BMSubsetComponent,
    "sub-set": subSet
  },
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    },
    // prop校验路径
    checkProp: {
      type: String,
      default: "column"
    }
  },
      // if (!this.isTiled) {
      //   // 平铺式消除border
      //   const a = document.querySelectorAll(".vxe-body--column");
      // if (a.length > 0) {
      //   a.forEach((el) => {
      //     el.style.borderBottom = "none";
      //   });
      // }
      // }
  watch: {
    // 触发输入限制
    "column.subDetails": {
      handler(val) {
      if (!this.isTiled) {
        console.log(111111);
        // 平铺式消除border .vxe-table .vxe-body--row .vxe-body--column
        const a = document.querySelectorAll(".vxe-table");
          if (a.length > 0) {
            a.forEach((el) => {
              el.style.borderBottom = "none";
            });
          }
        const b = document.querySelectorAll(".vxe-body--row");
          if (b.length > 0) {
            b.forEach((el) => {
              el.style.borderBottom = "none";
            });
          }
        const c = document.querySelectorAll(".vxe-body--column");
          if (c.length > 0) {
            c.forEach((el) => {
              el.style.borderBottom = "none";
            });
          }
        }
      }
    }
  },
  computed: {
    columnDisplayable() {
      return (item) => {
        if (item.pageHide === 0) {
        return false;
      }
      if (item.field && item.findColumnByField(item.field)) {
        return item.findColumnByField(item.field).displayable; // 再查下一遍的原因是因为明细区行数据对应的是fields字段  数据更新后可能会不准确，需要来自sub属性中的源头数据
      }
      return item.displayable;
      };
    },
    // 是否有子集
    hasSubSet() {
      return this.column.sub.some((item) => item.fieldType === this.subSet);
    },
    expandStyle() {
      if (this.tableWidth > 0) {
        return `width:${this.tableWidth}px;`;
      }
      return "";
    },

    isTiled() {
      // 卡片式如果有子集  默认设置为列表式
      return this.column.showStyle === "3" || this.column.showStyle === "1";
    },
    overflow() {
      return this.column.maxNum?.length > 0 && this.column.subDetails.length >= this.column.maxNum;
    },
    lineBtnData() {
      let btnList = [...this.buttonData];
      // 平铺按钮组移除新增按钮
      if (this.isTiled && !this.overflow) {
        // btnList.shift();
        // 超出最大值 只保留删除
      } else if (this.overflow) {
        btnList = [btnList[btnList.length - 1]];
      }
      return btnList.filter((btn) => this.column.functionList.includes(btn.id));
    },
    headAddConfig() {
      if (!this.column.editable || this.column.field === "sellArea" || this.column.allowAddFlag || this.column.subDetails.length > 0) { // 销假套餐无新增按钮
        return {
          type: "more"
        };
      }
      return { type: "add", rowDataMethod: this.headButtonClick };
    },
    lineEditConfig() {
      if (!this.column.editable) {
        return {};
      }
      return { data: this.lineBtnData, changeMethod: this.handleButtonClick };
    }
  },
  data() {
    return {
      tableWidth: 0,
      dialogVisible: false,
      addLineNums: "", // 批量添加的条数
      curOperatIndex: 0, // 当前操作的行索引
      subSet: BusinessFieldType.subSet,
      expands: [], // 子集展开数据集合
      subDetailsBak: [],
      headBtnData: [
        {
          name: "新增",
          icon: "tianjia-liebiao",
          id: "allowAddFlag",
          iconColor: "#3e90fe",
          fn: this.addOneLine
        }
      ],
      buttonData: [
        {
          name: "新增",
          id: "allowAddFlag",
          icon: "tianjia-liebiao",
          iconColor: "#3e90fe",
          fn: this.insertOneLine
        },
        {
          name: "批量添加",
          id: "multipleFlag",
          icon: "iconpiliangtianjia",
          iconColor: "#d59C78",
          fn: this.openbatchaddDialog
        },
        {
          name: "复制",
          id: "allowConflict",
          icon: "iconfuzhi",
          iconColor: "#FFA32B",
          fn: this.copyOneLine
        },
        {
          name: "删除",
          id: "completeFlag",
          icon: "shibai",
          iconColor: "#F76B6B",
          fn: this.deleteOneLine
        }
      ]
    };
  },
  methods: {
    setInitExpands() {
      // foldSet
      // this.column.foldSet === "2" && this.$emit("handleShowExpand", this.row);
    },
    filterConfig(fieldType) {
      // 业务关联 附件 基础数据 不支持搜索排序 收付信息
      const {
        associatedData, detailParent, shouFu, basicDataControl, cashObject, seeFlow, textComposition
      } = BusinessFieldType;
      if ([associatedData, detailParent, shouFu, basicDataControl, cashObject, seeFlow, textComposition].includes(fieldType)) {
        return null;
      }
      return { sortable: true };
    },
    handleTableFilterChange(data) {
      console.log(data);
      if (data.sortData) {
        const sortData = JSON.parse(data.sortData);
        const type = sortData.fieldType;
        this.column.subDetails = this.sort(this.column.subDetails, data.field, type, Number(data.sortType));
      } else {
        this.column.subDetails = this.subDetailsBak;
      }
    },
    getData(row) {
     return this.column.showContent.find((item) => item.field === row.field) || {
        field: row.field, fieldType: row.fieldType, name: row.name
      };
    },
    orderASCII(str1, str2) {
        if (!str1 && !str2) {
            return 0;
        }
        if (!str1) {
            return -1;
        }
        if (!str2) {
            return 1;
        }
        const code1 = str1.charAt(0).charCodeAt();
        const code2 = str2.charAt(0).charCodeAt();
        if (code2 > code1) {
            return -1;
        } if (code2 < code1) {
            return 1;
        }
        return this.orderASCII(str1.substring(1, str1.length), str2.substring(1, str2.length));

        // for (let i = str.length - 1; i >= 0; i--) {
        //     const chart = str.charAt(i);
        //     const code = chart.charCodeAt();
        //     asc += code;
        // }
    },
    sort(arr, field, type, order) {
      type = `${type}`;
      return arr.sort((av, bv) => {
          const af = av.fields.find((fieldItem) => fieldItem.field === field);
          const bf = bv.fields.find((fieldItem) => fieldItem.field === field);
          const a = af.showValue;
          const b = bf.showValue;
          console.log(a, b);
          let v = 0;
          if (type === "1" || type === "2") {
              v = this.orderASCII(a, b);
          } else if (type === "3" || type === "4") {
              if (b > a) {
                  v = -1;
              } else if (b < a) {
                  v = 1;
              }
          } else if (type === "5") {
              if (!a && !b) {
                  v = 0;
              } else if (!a) {
                  v = -1;
              } else if (!b) {
                  v = 1;
              } else {
                  v = this.orderASCII(a.name, b.name);
              }
          } else if (type === "7") {
              if (!b) {
                  v = 1;
              } else if (!a) {
                  v = -1;
              } else {
                  const c = new Date(a.split(",")[0]).getTime();
                  const d = new Date(b.split(",")[0]).getTime();
                  if (d > c) {
                      v = -1;
                  } else if (d < c) {
                      v = 1;
                  } else if (a.split(",")[1] && b.split(",")[1]) {
                    // 等于
                    const c1 = new Date(a.split(",")[1]).getTime();
                    const d1 = new Date(b.split(",")[1]).getTime();
                    if (d1 > c1) {
                        v = -1;
                    } else if (d1 < c1) {
                        v = 1;
                    }
                  }
              }
          } else if (type === "9") {
              if (!af.columnValue && !bf.columnValue) {
                  v = 0;
              } else if (!af.columnValue) {
                  v = -1;
              } else if (!bf.columnValue) {
                  v = 1;
              } else {
                  v = this.orderASCII(af.columnValue[0].name, bf.columnValue[0].name);
              }
          } else {
              v = 0;
          }
          if (order) { // 1 降序
              v *= (-1);
          } else { // 0 升序
          }
          return v;
      });
    },
    // 行展示的宽度控制
    columnStyleWidth(col) {
      if (col.__widthStr__) return col.__widthStr__;// 取缓存的计算结果 减少不必要的重复计算
      let widthStr = "";
      if (col.isBelongToAreaColumn) {
        // 区域里面是垂直flex,不能自动展开
        widthStr = "";
      } else if (!col.fieldLengthType || (String(col.fieldLengthType) === "1")) {
        widthStr = "auto";
      } else {
        const config = col.fieldLengthType.split(",");
        const widthType = config[0];
        let length = "auto";
        switch (widthType) {
          case "2":
            length = `${config[1]}%`;
            break;
          case "3":
            length = `${config[1]}px`;
            break;
          default:
            // 不会执行
            length = "auto";
        }
        widthStr = `${length}`;
      }
      widthStr = col.fieldType === BusinessFieldType.address ? "400px" : `${widthStr}`;
      col.__widthStr__ = widthStr;//! 缓存计算结果
      return widthStr;
    },
    // 是否显示字段
    isShowField(field) {
      // console.log(this)
      const curField = this.column.sub.find((it) => it.field === field);
      console.log(3123123123, curField.name, curField.displayable);
      return curField.displayable;
    },
    subSetOptions(row) {
      return row.showSubset;
    },
    subSetFindIndex(row) {
      return row.fields.findIndex((item) => row.showSubset === item);
    },
    // 显示或隐藏当前行子集
    handleShowExpand(subset, row, resolve) {
      if (row.showSubset && row.showSubset === subset) {
        Vue.set(row, "showSubset", null);
        this.$refs.table.setRowExpansion(row, false);
        resolve(false);
      } else {
        Vue.set(row, "showSubset", subset);
        this.$refs.table.setRowExpansion(row, true);
        resolve(true);
      }
    },
    // 配合 expand-row-keys
    getRowKeys(row) {
      return row.lineId;
    },
    // 表头样式处理
    headCellStyle() {
      return {
        fontSize: "12px",
        color: "#333",
        fontWeight: 500
      };
    },
    // 行处理
    tableRowStyle({ rowIndex }) {
      const styles = {};
      // 最后一行无border-bottom  目前无效
      if (rowIndex === this.column.subDetails?.length - 1) {
        styles.borderBottom = "none";
      }
      // 偶数行 带背景颜色
      if (rowIndex % 2 !== 0) {
        styles.backgroundColor = "#F5F8FC";
      }
      return styles;
    },
    // 单元格class
    cellClassFormat({ columnIndex }) {
      let cls = "";
      // 平铺模式
      if (!this.isTiled && columnIndex === 1) {
        cls = "no-pad-rt";
        // 行末尾合并单元格  button组单独设置无padding-right
      } else if (columnIndex === this.column.sub?.length) {
        cls = "no-pad-rt";
        // 行最后一个空td 无右侧边框
      } else if (columnIndex === this.column.sub?.length + 1) {
        cls = "last-td";
      }
      return cls;
    },
    // 倒数第二 合并 倒数第一单元格
    arraySpanMethod({ columnIndex }) {
      // 平铺式 第一列序号  第二列内容 第三列expand 合并1-2
      if (!this.isTiled && columnIndex === 1) {
        return {
          rowspan: 1,
          colspan: 2
        };
      }
      if (!this.isTiled && columnIndex === 2) {
        return {
          rowspan: 0,
          colspan: 0
        };
      }
      // 列表式 倒数第二列合并倒数第一列   倒数第一列expand隐藏
      if (columnIndex === this.column.sub?.length) {
        return {
          rowspan: 1,
          colspan: 2
        };
      }
      if (columnIndex === this.column.sub?.length + 1) {
        return {
          rowspan: 0,
          colspan: 0
        };
      }
      return [1, 1];
    },
    // 列表式 头部 add
    headButtonClick() {
      const btnData = this.headBtnData[0];
      const { fn } = btnData;
      typeof fn === "function" && fn();
    },
    // 行button组 点击处理
    buttonClick(index, icon, lineIndex) {
      const btnData = this.lineBtnData[index];
      const { fn } = btnData;
      typeof fn === "function" && fn(lineIndex);
    },
    handleButtonClick({ rowIndex, btnData }) {
      const { fn } = btnData;
      typeof fn === "function" && fn(rowIndex);
    },
    // 打开批量新增弹框
    openbatchaddDialog(index) {
      this.dialogVisible = true;
      this.curOperatIndex = index;
    },
    /**
     * @description: 批量添加
     * @param {*} type 1：覆盖新增 2：依次新增&&新增
     * @return {*}
     */
    batchAddLine(type) {
      this.column.batchAddLine(type, this.addLineNums, this.curOperatIndex);
      this.dialogVisible = false;
      this.addLineNums = "";
    },
    // 新增一行
    addOneLine() {
      if (Number(this.column.maxNum) && this.column.subDetails?.length >= this.column.maxNum) {
        this.$message(`该明细最大支持${this.column.maxNum}行`);
        return;
      }
      this.column.addOneLine();
    },
    /**
     * @description: 插入明细行
     * @param {*} index  插入的位置
     * @return {void}  void|undefined
     */
    insertOneLine(index) {
      if (Number(this.column.maxNum) && this.column.subDetails?.length >= this.column.maxNum) {
        this.$message(`该明细最大支持${this.column.maxNum}行`);
        return;
      }
      this.column.insertOneLine(index);
    },
    deleteOneLine(index) {
      this.column.deleteOneLine(index);
    },
    // 复制一行
    copyOneLine(index) {
      if (Number(this.column.maxNum) && this.column.subDetails?.length >= this.column.maxNum) {
        this.$message(`该明细最大支持${this.column.maxNum}行`);
        return;
      }
      this.column.copyOneLine(index);
    }
  },
  beforeCreate() {
    this.$options.components.BusinessColumnRendering = () => import("../../index");
  },
  created() {
    console.log(this.checkProp, "checkProp-paraent");

    // this.setInitExpands();
  },
  async mounted() {
    const that = this;
    this.subDetailsBak = cloneDeep(this.column.subDetails);
    console.log(this.column.sub, "column.subcolumn.sub");

    window.onresize = (() => {
      this.$nextTick(() => {
        that.tableWidth = that.$el.clientWidth - 2;
      });
    })();
  }
};
</script>
<style scoped lang="scss">
.detail-table .attachment-box{
  background: transparent;
}
.el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
  background-color: #3e90fe;
  border-color: #3e90fe;
}
.el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner{
  background-color: #3e90fe;
  border-color: #3e90fe;
}
.en-button--blue.en-btn-normal{
  background-color: #3e90fe;
  border-color: #3e90fe;
}
</style>

<style scope lang="scss">
.vxe-table .vxe-body--row{
  background-color: #FFFFFF!important;
}
  .add-dialog{
    .input-line-wrap{
      display: flex;
      align-items: center;
      span{
        flex: 0 1 auto;
      }

    }
    .dialog-footer{
      margin-top: 30px;
      display: inline-block;
    }
    .add-line-input{
      flex: 1;
      margin-left: 13px;
    }
    .info-icon{
      color: #f9bd28;
    }
  }
  .info-popover{
    background: rgba($color: #666975, $alpha: 0.9);
    border-color: #666a75;
    color: #fff;
    font-size: 12px;
    .info-list{
      margin: 0;
      padding: 0;
      padding-left: 13px;
      li{
        list-style: disc;
      }
      .num1{
        color:#3e90fe ;
      }
      .num2{
         color:#26c393 ;
      }
      .content{
        color: #fff;
        margin: 10px 0;
      }
    }
    .popper__arrow::after{
          border-top-color:#666a75!important;
          border-bottom-color: #666a75!important;
    }
  }
  .is-list .address-container .address-input .el-input__inner{
    background:transparent!important;
  }
  .is-list .address-container .address-input .el-input__inner:focus{
    background:#fff!important;
  }
  .is-list .vxe-hover-row-right-wrapper{
    z-index: 200!important;
  }
  .is-list .en-expand-buttons{
    display: flex;
    align-items: center;
    height: 100%;
  }
  .is-list .vxe-body--row .vxe-body--column{
      border-right: none!important;
  }
  .is-list .el-input__inner:hover{
    border: 1px solid transparent!important;
  }
  .is-list .el-input-group__prepend{
    line-height: 1;
  }
  .is-list .el-input__inner{
    background: transparent!important;
    height: 43px;
    border-radius: 0;
    border: 1px solid transparent!important;
  }
  .is-list .el-input__inner:focus{
    border: 1px solid #4694df!important;
    background:#fff!important;
  }
  .is-list .el-select .el-input__icon{
    line-height: 43px;
    height: 43px;
  }
  .is-list .bm-sub-content .vxe-table .vxe-table--body .vxe-cell.c--padding{
    padding: 0;
  }
  .is-list{
    .vxe-hover-row-right-wrapper{
        height: 45px;
        background-color: transparent;
        .en-expand-buttons{
          display: flex;
          align-items: center;
          margin-top: 5px;
          height: 34px;
          background-color: rgba(236,244,252,0.9)!important;
        }
      }
      .bm-subSet-expand {
        .vxe-hover-row-right-wrapper{
          height: 45px;
          background-color: transparent;
          .en-expand-buttons{
            display: flex;
            align-items: center;
            margin-top: 5px;
            height: 34px;
            background-color: rgba(238,253,245,0.9)!important;
          }
        }
      }
  }
  .is-ping{
      .business-column-rendering{
      align-items: center;
      }
      .vxe-body--row{
        height: 60px;
      }
      .vxe-hover-row-right-wrapper{
        height: 60px;
        .en-expand-buttons{
          display: flex;
          align-items: center;
          margin-top: 12px;
          height: 34px;
          background-color: rgba(236,244,252,0.9)!important;
        }
      }
      .row--hover{
        .vxe-body--column{
          height: 60px;
          position: relative;
        }
        .vxe-body--column:first-of-type::after{
          content: "";
          width: 3px;
          height: 60px;
          background: #3E90FE;
          position: absolute;
          left: 0;
          top:0;
        }
       }
      .bm-subSet-expand {
        .row--hover{
          .vxe-body--column:first-of-type::before{
          content: "";
          width: 3px;
          height: 100%;
          background: #45D489!important;
          position: absolute;
          left: 0;
          top:0;
          z-index: 1001;
          }
        }
        .vxe-hover-row-right-wrapper{
          height: 60px;
          .en-expand-buttons{
            display: flex;
            align-items: center;
            margin-top: 15px;
            height: 34px;
            background-color: rgba(238,253,245,0.9)!important;
          }
        }
      }
      .vxe-body--column{
        border-bottom: none !important;
      }
  }
  .bm-subSet-expand {
    display: flex;
    .table{
      border-radius: 6px;
      overflow: hidden;
    }
    .body--wrapper{
      // .vxe-table-box{
        .row--hover{
          background-color: #EEFDF5!important;
          position: relative;
        }
        .vxe-body--column{
          height: 45px;
          border-right: none;
          border-bottom: none;
        }
      // }
    }
  }
  .wrapper-tile {
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 10px 0;
    .business-column-rendering{
      margin-right: 10px;
      &:last-child{
        margin-right: 0;
      }
    }
  }

.detail-content {
  .el-table__expanded-cell[class*=cell]{
    padding: 10px;
  }
  td {
    overflow-y: auto;
  }
  .cell {
    overflow: visible;
    .wrapper {
      display: flex;
      .business-column-rendering{
        margin-right: 10px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .td-operation {
      display: flex;
      flex-flow: row;
      padding-left: 10px;
    }
  }
  .no-pad-rt {
    border-right: none;
    .cell {
      padding-right: 0;
    }
  }
  .last-td {
    border-right: none;
  }
}

.head-requred {
  color: #ff3e3e;
  margin: 0 2px;
}
.vxe-hover-row-right-wrapper{
  display: flex
}
.button{
  background-color: transparent !important;
}
.en-expand-buttons{
  background-color: transparent !important;
}
.image-and-text{
  width: 30px;
}
.sub-set {
  display: flex;
  align-items: center;
  font-size: 12px;
  .sub-label {
    padding-right: 12px;
  }
  .sub-btn {
    background: #E3F7F1;
    color: #26c393;
    cursor: pointer;
    padding: 0 20px;
    border-radius: 4px;
  }
}

  .vxe-table .vxe-body--expanded-cell {
    padding: 0;
  }
.pop-icon{
  vertical-align: text-bottom;
  color:#fac135 ;
  cursor: pointer;
}
.el-collapse-item__wrap{
  border-bottom: none
}
// .vxe-body--column{
//   .vxe-cell:first-of-type{
//     text-align: center;
//   }
// }

</style>
